import SvgIconWrapper from "./SvgIconWrapper";

export default function EmptyRequestIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2167_15554)">
          <path
            d="M26.8008 20.0821H28.8028V21.9589H26.8008V20.0821Z"
            fill="currentColor"
          />
          <path
            d="M30.8047 20.0821H32.8067V21.9589H30.8047V20.0821Z"
            fill="currentColor"
          />
          <path
            d="M34.8086 20.0821H36.8106V21.9589H34.8086V20.0821Z"
            fill="currentColor"
          />
          <path
            d="M12.851 6.00587H14.7279V3.94135H16.7924V2.06452H14.7279V0H12.851V2.06452H10.7865V3.94135H12.851V6.00587Z"
            fill="currentColor"
          />
          <path
            d="M18.8555 29.9667H24.7363V24.086H18.8555V29.9667ZM20.7324 25.9628H22.8594V28.0899H20.7324V25.9628Z"
            fill="currentColor"
          />
          <path
            d="M18.8555 37.9746H24.7363V32.0939H18.8555V37.9746ZM20.7324 33.9707H22.8594V36.0978H20.7324V33.9707Z"
            fill="currentColor"
          />
          <path
            d="M18.8555 45.9824H24.7363V40.1017H18.8555V45.9824ZM20.7324 41.9785H22.8594V44.1056H20.7324V41.9785Z"
            fill="currentColor"
          />
          <path
            d="M38.8125 30.0919C33.8796 30.0919 29.8663 34.1052 29.8663 39.0381C29.8663 43.971 33.8796 47.9843 38.8125 47.9843C43.7455 47.9843 47.7588 43.971 47.7588 39.0381C47.7588 34.1052 43.7455 30.0919 38.8125 30.0919ZM38.8125 46.1075C34.9145 46.1075 31.7431 42.9362 31.7431 39.0381C31.7431 35.14 34.9145 31.9687 38.8125 31.9687C42.7106 31.9687 45.8819 35.14 45.8819 39.0381C45.8819 42.9362 42.7106 46.1075 38.8125 46.1075Z"
            fill="currentColor"
          />
          <path
            d="M52.7636 48.6591V12.0117C52.7636 10.9423 51.8937 10.0723 50.8243 10.0723H40.7519V5.50537C40.7519 4.71197 40.1064 4.06647 39.313 4.06647H24.2984C23.505 4.06647 22.8594 4.71197 22.8594 5.50537V10.0723H12.7871C11.7177 10.0723 10.8477 10.9423 10.8477 12.0117V62.0606C10.8477 63.13 11.7177 64 12.7871 64H50.8243C51.8937 64 52.7636 63.13 52.7636 62.0606V57.3193L56.8301 61.3858L61.1601 57.0557L52.7636 48.6591ZM24.7363 5.9433H38.8751V10.0723H24.7363V5.9433ZM22.7343 11.9492H40.877V16.0782H22.7343V11.9492ZM50.8868 62.0606C50.8868 62.0951 50.8587 62.1232 50.8243 62.1232H12.7871C12.7526 62.1232 12.7246 62.0951 12.7246 62.0606V12.0117C12.7246 11.9772 12.7526 11.9492 12.7871 11.9492H20.8575V14.0762H14.8516V49.4365L25.4111 59.9961H48.7596V55.0538H46.8828V58.1192H26.7381V48.1095H16.7283V15.9531H20.8574V16.5161C20.8574 17.3095 21.5029 17.955 22.2963 17.955H41.3148C42.1082 17.955 42.7538 17.3095 42.7538 16.5161V15.9531H46.8828V27.0264H48.7596V14.0762H42.7538V11.9492H50.8241C50.8585 11.9492 50.8867 11.9772 50.8867 12.0117V34.3569C49.0061 29.5231 44.3031 26.088 38.8124 26.088H26.8007V27.9648H32.1035C28.3647 30.2384 25.8623 34.3515 25.8623 39.0381C25.8623 46.1788 31.6717 51.9883 38.8124 51.9883C41.2754 51.9883 43.5799 51.2968 45.5425 50.0983L50.8867 55.4425V62.0606H50.8868ZM18.0555 49.9863H24.8614V56.7922L18.0555 49.9863ZM48.7647 47.3146L50.4981 49.0479L48.8223 50.7237L47.089 48.9903C47.6971 48.4838 48.2583 47.9228 48.7647 47.3146ZM38.8125 50.1114C32.7067 50.1114 27.7392 45.144 27.7392 39.0381C27.7392 32.9323 32.7067 27.9648 38.8125 27.9648C44.9184 27.9648 49.8858 32.9323 49.8858 39.0381C49.8858 45.144 44.9184 50.1114 38.8125 50.1114ZM49.8727 45.7682C50.2683 45.1203 50.6084 44.4351 50.8868 43.7193V46.7823L49.8727 45.7682ZM50.1495 52.0508L51.8252 50.3751L58.5059 57.0557L56.83 58.7316L50.1495 52.0508Z"
            fill="currentColor"
          />
          <path
            d="M38.9502 34.0989C38.1377 34.0621 37.3682 34.3494 36.7818 34.9096C36.2037 35.4617 35.872 36.2369 35.872 37.0361H37.7489C37.7489 36.7427 37.8659 36.4696 38.0781 36.2669C38.29 36.0644 38.5694 35.9601 38.8638 35.9739C39.3967 35.9984 39.8502 36.4518 39.8747 36.9847C39.8913 37.3458 39.7267 37.6877 39.4343 37.8991C38.4572 38.6056 37.8739 39.7798 37.8739 41.0402H39.7507C39.7507 40.3804 40.0435 39.7747 40.534 39.4201C41.3409 38.8365 41.7953 37.894 41.7495 36.8986C41.6807 35.3977 40.451 34.168 38.9502 34.0989Z"
            fill="currentColor"
          />
          <path
            d="M37.8741 42.0411H39.7509V44.043H37.8741V42.0411Z"
            fill="currentColor"
          />
          <path
            d="M57.8311 24.9619C59.4524 24.9619 60.7715 23.6428 60.7715 22.0215C60.7715 20.4002 59.4524 19.0811 57.8311 19.0811C56.2098 19.0811 54.8907 20.4002 54.8907 22.0215C54.8907 23.6428 56.2098 24.9619 57.8311 24.9619ZM57.8311 20.9579C58.4175 20.9579 58.8946 21.435 58.8946 22.0215C58.8946 22.6079 58.4175 23.085 57.8311 23.085C57.2447 23.085 56.7676 22.6079 56.7676 22.0215C56.7676 21.435 57.2447 20.9579 57.8311 20.9579Z"
            fill="currentColor"
          />
          <path
            d="M5.78027 15.0772C4.15894 15.0772 2.8399 16.3962 2.8399 18.0176C2.8399 19.6389 4.15894 20.958 5.78027 20.958C7.4016 20.958 8.72064 19.6389 8.72064 18.0176C8.72064 16.3962 7.4016 15.0772 5.78027 15.0772ZM5.78027 19.0811C5.19382 19.0811 4.71673 18.604 4.71673 18.0176C4.71673 17.4311 5.19382 16.954 5.78027 16.954C6.36672 16.954 6.84381 17.4311 6.84381 18.0176C6.84381 18.604 6.36672 19.0811 5.78027 19.0811Z"
            fill="currentColor"
          />
          <path
            d="M50.8868 8.00782H52.7637V5.9433H54.8282V4.06647H52.7637V2.00195H50.8868V4.06647H48.8223V5.9433H50.8868V8.00782Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2167_15554">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
